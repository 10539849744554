<template>
  <div>
    <b-notification
      v-if="error"
      has-text-centered
      type="is-danger"
      aria-close-label="Close notification"
      role="alert"
      v-html="error"
    >
    </b-notification>

    <form @submit.prevent="onSignin">
      <div class="field">
        <label class="label" v-text="$getTranslation('auth.form.email.label')"></label>
        <div class="control is-clearfix">
          <input
            type="text"
            v-model="email"
            autocomplete="off"
            name="email"
            class="input"
            :placeholder="$getTranslation('auth.form.email.placeholder')"
          />
        </div>
      </div>
      <div class="field">
        <label class="label" v-text="$getTranslation('auth.form.password.label')"></label>
        <div class="control is-clearfix">
          <input
            type="password"
            v-model="password"
            autocomplete="off"
            name="password"
            class="input"
            :placeholder="$getTranslation('auth.form.password.placeholder')"
          />
        </div>
      </div>
      <!-- <div class="field">
        <label class="b-checkbox checkbox is-thin">
          <input type="checkbox" true-value="true" value="false">
          <span class="check is-black"></span>
          <span class="control-label" v-text="$getTranslation('auth.remember')"></span>
        </label>
      </div> -->
      <hr />
      <div class="field is-grouped">
        <div class="control">
          <button
            type="submit"
            class="button is-black"
            :class="classObj"
          >
            {{ $getTranslation('auth.signin.button') }}
          </button>
        </div>
        <div class="control">
          <a href="/forgot-password" class="button is-outlined is-black" v-text="$getTranslation('auth.forgot.check')"></a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { loggedInPage } from "@/api-routes.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "app-login",
  // components: { HeroBar, FilePicker, RadioPicker, CheckboxPicker, CardComponent, TitleBar },
  data() {
    return {
      email: "",
      password: "",
      remember: false
    };
  },
  computed: {
    ...mapGetters([
      "isLoggedUser",
      "isLoggedIn",
      "loading",
      "error"
    ]),

    classObj() {
      if (this.loading) {
        return " is-loading"
      }
      return ""
    }
  },

  /*
  This is probably superfluous and has big potential for causing bugs:
  watch: {
    // watch user computed proprerty in mapGetters
    isLoggedUser(value) {
      // console.log('login watch -logged in!!!', value)
      if (
        value !== null &&
        (value !== undefined || this.isLoggedIn === undefined)
      ) {
        // console.log('should be redirected: ', loggedInPage)
        this.$router.push(loggedInPage)
      }
    }
  },
  */

  methods: {
    ...mapActions([
      "signUserIn",
      "setError",
      "clearError"
    ]),

    validate() {
      this.setError("")
      if (this.email === "" || this.password === "") {
        this.setError(this.$getTranslation('auth.signin.empty'))
      }
    },

    // Add handleSubmit to prevent form being submitted twice
    // TODO move it into auth mixin
    handleSubmit() {
      // apply some other actions
      return false;
    },

    onSignin() {
      this.validate()

      if (!this.error) {
        let user = {
          username: this.email,
          password: this.password
        }
        this.signUserIn(user)
          .then(data => {
            this.$buefy.snackbar.open({
              message: this.$getTranslation('general.message.welcomeBack') + data.name + "!",
              queue: false
            })
            this.$router.push({ name: "dashboard" })
          })
      }
    }
  }
}
</script>
