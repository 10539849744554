<template>
  <div>
      <b-notification
        v-if="error"
        has-text-centered
        type="is-danger"
        aria-close-label="Close notification"
        role="alert"
        v-html="getErrors()"
      >
      </b-notification>
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form
            ref="form"
            method="POST"
            action="/register"
            @submit.prevent="handleSubmit"
          >
            <InputWithValidation
              horizontal
              rules="required"
              type="name"
              v-model="form.name"
              icon="account"
              :label="$getTranslation('auth.form.name.label')"
              :placeholder="$getTranslation('auth.form.name.placeholder')"
            />

            <InputWithValidation
              horizontal
              rules="required|email"
              type="email"
              v-model="form.email"
              icon="email"
              :label="$getTranslation('auth.form.email.label')"
              :placeholder="$getTranslation('auth.form.email.placeholder')"
            />

            <InputWithValidation
              horizontal
              rules="required"
              vid="password"
              type="password"
              v-model="form.password"
              icon="lock"
              :label="$getTranslation('auth.form.password.label')"
              :placeholder="$getTranslation('auth.form.password.placeholder')"
              password-reveal
            />

            <InputWithValidation
              horizontal
              rules="required|confirmed:password"
              type="password"
              v-model="password_confirm"
              icon="lock"
              :label="$getTranslation('auth.form.passwordConfirm.label')"
              :placeholder="$getTranslation('auth.form.passwordConfirm.placeholder')"
              password-reveal
            />

            <hr />
            <div class="field is-grouped">
              <div class="control">
                <b-button
                  native-type="submit"
                  type="is-black"
                  @click="passes(submit)"
                  >{{ $getTranslation('form.submit') }}</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-black is-outlined" @click="reset"
                  >{{ $getTranslation('form.reset') }}</b-button
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
  </div>
</template>

<script>
import { loggedInPage, ApiUsers } from "@/api-routes.js";
import { mapGetters, mapActions } from "vuex";
import FormTemplateMixin from "@/mixins/FormTemplateMixin";

export default {
  name: "app-register",
  mixins: [FormTemplateMixin],
  data() {
    return {
      id: 0,
      method: "post",
      endpoint: ApiUsers,
      password_confirm: "",
      formObj: {
        name: null,
        // TODO: check if unique
        email: null,
        password: null
      }
    };
  },
  computed: {
    ...mapGetters(["user"]),

    form() {
      // let data = this.user;
      let form = {};
      for (let field in this.formObj) {
        form[field] = this.formObj[field];
      }
      // console.log('form !!! ', form)
      return form;
    }
  },
  methods: {
    ...mapActions([
      "signUserUp",
      "fetchUser",
    ]),

    submit() {
      this.signUserUp(this.form)
        .then(() => {
          setTimeout(() => {
            if(!this.error) {
              this.$emit('changeTab', 0, this.$getTranslation('auth.signup.message'))
            }
          }, 500)
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message:  'Something went wrong',
            type: 'is-danger',
            position: 'is-top',
          })
        })
    }
  },
  created() {
    // console.log('this id: ', this.id)
    if (this.id > 0) {
      this.id = this.$route.params.id
      this.fetchUser(this.id)
      this.method = "put"
    }
  }
}
</script>
