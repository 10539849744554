<template>
  <div v-if="!isLoggedIn" class="mt-6">
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.message.welcome') }}
    </hero-bar>
    <section class="section is-main-section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths">
            <b-tabs class="box" v-model="activeTab" @input="changeTab">
              <b-tab-item :label="$getTranslation('auth.signin.title')" icon="lock">
                <login />
              </b-tab-item>
              <b-tab-item :label="$getTranslation('auth.signup.title')" icon="pen" >
                <register  @changeTab="activated" />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <h1>{{ $getTranslation('general.message.welcome') }}</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex"
import HeroBar from "@/components/HeroBar"
import Register from "@/components/auth/Register"
import Login from "@/components/auth/Login"

export default {
  name: "home",
  components: {
    HeroBar,
    Register,
    Login
  },

  data() {
    return {
      activeTab:0
    };
  },
  computed: {
    ...mapGetters([
      "isLoggedUser",
      "isLoggedIn",
      "error"
    ])
  },
  methods: {
    ...mapActions([
      "clearError"
    ]),

    activated(tabId, message) {
      if(!this.error) {
        this.activeTab = tabId
        this.$buefy.snackbar.open({
          message: message,
          position: 'is-top',
          indefinite: true,
          queue: false
        })
      }
    },

    changeTab() {
      this.clearError()
    }
  }
}
</script>
